<template>
    <div>
        <v-row>
                <v-col cols="4">
                    <v-select :items="['Phone']" label="Filters" outlined dense multiple required chips
                        v-model="filterItems" prepend-icon="" append-icon="mdi-filter"></v-select>
                </v-col>
                <v-col cols="4" v-show="filterItems.includes('Phone')">
                    <v-text-field outlined dense v-model="filterByPhone" required label="Mobile Number"
                        append-icon="mdi-package"></v-text-field>
                </v-col>
        </v-row>
        <v-dialog v-model="imgdialog" max-width="450px">
            <v-card tile color="#f4f6f6">
                <v-card-text>
                    <v-container>
                        <v-img contain max-height="150" :src="selectedSrc"></v-img>
                    </v-container>
                </v-card-text>

                <v-divider class="primary" />
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="error" @click="() => { imgdialog = false }">
                        Back
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- checkbox to show custom frames -->
        <v-row>
            <v-col cols="12">
                <v-checkbox 
                @change="changeView()"
                label="Show Custom Frames"
                ></v-checkbox>
            </v-col>
        </v-row>
        <v-dialog v-model="dialog" max-width="450px" persistent>
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                    <v-icon dark>
                        mdi-plus
                    </v-icon>
                    Create
                </v-btn>
            </template>
            <v-card tile>
                <v-card-title class="primary white--text">
                    <span class="text-h5"><v-icon large color="white" class="mr-2">{{ formIcon }}</v-icon>{{ formTitle
                    }}</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row no-gutters>
                            <!-- read only id field -->
                            <v-col cols="12">
                                <v-text-field readonly dense v-model="editedItem.id" label="ID"
                                    append-icon="mdi-numeric"></v-text-field>
                            </v-col>
                            <!-- Custom protocol fields -->
                            <v-col cols="12">
                                <label>Is Custom?</label>
                                <v-radio-group required v-model="editedItem.is_custom" row>
                                    <v-radio label="Yes" :value="true"></v-radio>
                                    <v-radio label="No" :value="false"></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols="12" v-if="editedItem.is_custom">
                                <v-autocomplete dense outlined v-model="editedItem.user_available_to" :items="customers"
                                    :multiple="true" label="User Available To"
                                    required @input="editedIndex == -1 ? $v.editedItem.user_available_to.$touch() : null"
                                    @blur="editedIndex == -1 ? $v.editedItem.user_available_to.$touch() : null"
                                    append-icon="mdi-account"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" class="my-2 d-flex justify-center"
                                v-if="selectedImage">
                                <v-img contain max-height="150" :src="selectedImage"></v-img>
                            </v-col>
                            <v-col cols="12">
                                <v-file-input label="Cover" outlined dense @change="getImage('frame')"
                                    v-model="editedItem.img" prepend-icon="" append-icon="mdi-image"></v-file-input>
                            </v-col>
                            <v-col cols="12" class="my-2 d-flex justify-center" v-if="selectedSocialImage && !editedItem.is_custom">
                                <v-img contain max-height="150" :src="selectedSocialImage"></v-img>
                            </v-col>
                            <v-col cols="12">
                                <v-file-input label="Social Cover" outlined dense @change="getImage('social_frame')"  v-if="!editedItem.is_custom"
                                    v-model="editedItem.social_img" prepend-icon="" append-icon="mdi-image"></v-file-input>
                            </v-col>

                            <v-col cols="12" class="my-2 d-flex justify-center" v-if="selectedFrameTemplate">
                                <v-img contain max-height="150" :src="selectedFrameTemplate"></v-img>
                            </v-col>
                            <v-col cols="12">
                                <v-file-input label="Frame Template" outlined dense
                                    @change="getImage('sample_frame_template')" v-model="editedItem.sample_template"
                                    prepend-icon="" append-icon="mdi-image"></v-file-input>
                            </v-col>

                            <v-col cols="12">
                                <v-text-field outlined dense v-model="editedItem.name" :error-messages="nameErrors" required
                                    @input="editedIndex == -1 ? $v.editedItem.name.$touch() : null"
                                    @blur="editedIndex == -1 ? $v.editedItem.name.$touch() : null" label="Name"
                                    append-icon="mdi-rename-box"></v-text-field>
                            </v-col>
                            <!-- label -->
                            <label v-if="!editedItem.is_custom">Font Colour</label>
                            <v-color-picker v-model="editedItem.font_colour" :error-messages="font_colourErrors" required v-if="!editedItem.is_custom"
                                @input="editedIndex == -1 ? $v.editedItem.font_colour.$touch() : null"
                                @blur="editedIndex == -1 ? $v.editedItem.font_colour.$touch() : null"></v-color-picker>
                            <label v-if="!editedItem.is_custom">Social Font Colour</label>
                            <v-color-picker v-model="editedItem.social_font_colour" v-if="!editedItem.is_custom"
                                :error-messages="social_font_colourErrors" required
                                @input="editedIndex == -1 ? $v.editedItem.social_font_colour.$touch() : null"
                                @blur="editedIndex == -1 ? $v.editedItem.social_font_colour.$touch() : null"></v-color-picker>
                            <label  v-if="!editedItem.is_custom">Designation Font Colour</label>
                            <v-color-picker v-model="editedItem.designation_font_colour"  v-if="!editedItem.is_custom"
                                :error-messages="social_font_colourErrors" required
                                @input="editedIndex == -1 ? $v.editedItem.designation_font_colour.$touch() : null"
                                @blur="editedIndex == -1 ? $v.editedItem.designation_font_colour.$touch() : null"></v-color-picker>
                            <v-col cols="12">
                                <v-select dense outlined v-model="editedItem.frame_type" 
                                    :items="['Political', 'Business', 'NGO', 'Individual']" label="Type"
                                    :error-messages="typeErrors" required
                                    @input="editedIndex == -1 ? $v.editedItem.frame_type.$touch() : null"
                                    @blur="editedIndex == -1 ? $v.editedItem.frame_type.$touch() : null"
                                    append-icon="mdi-briefcase"></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-select v-show="editedItem.frame_type == 'Political'" outlined :multiple="true" dense
                                    :items="politicals" v-model="editedItem.political_party" :searchable="true"
                                    :error-messages="political_partyErrors" required
                                    @input="editedIndex == -1 ? $v.editedItem.political_party.$touch() : null"
                                    @blur="editedIndex == -1 ? $v.editedItem.political_party.$touch() : null"
                                    label="Political party" append-icon="mdi-gamepad-circle"></v-select>
                            </v-col>
                            <v-col cols="12"  v-if="!editedItem.is_custom">
                                <label>Is Premium</label>
                                <v-radio-group required v-model="editedItem.premium" row>
                                    <v-radio label="Yes" :value="true"></v-radio>
                                    <v-radio label="No" :value="false"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-alert v-if="error" dense type="error">
                                    {{ error }}
                                </v-alert>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-divider class="primary" />
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="error" @click="close">
                        Cancel
                    </v-btn>
                    <v-btn color="success" @click="save">
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="error white--text">
                    <v-icon color="white" class="mr-2">mdi-delete</v-icon>
                    You sure you want to delete this item?
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="closeDelete">Cancel
                    </v-btn>
                    <v-btn color="success" @click="deleteItemConfirm">OK
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-data-table :headers="headers" :items="filterFinalData"  sort-by="calories" class="elevation-3">
            <template v-slot:item.img="{ item }">
                <v-avatar class="profile my-2" color="grey" size="80" tile @click="openImage(item.img)">
                    <v-img contain v-if="item.img" :src="item.img"></v-img>
                </v-avatar>
            </template>
            <template v-slot:item.social_img="{ item }">
                <v-avatar class="profile my-2" color="grey" size="80" tile @click="openImage(item.social_img)">
                    <v-img contain v-if="item.social_img" :src="item.social_img"></v-img>
                </v-avatar>
            </template>
            <template v-slot:item.sample_template="{ item }">
                <v-avatar class="profile my-2" color="grey" size="80" tile @click="openImage(item.sample_template)">
                    <v-img contain v-if="item.sample_template" :src="item.sample_template"></v-img>
                </v-avatar>
            </template>
            <template v-slot:item.party_name="{ item }">
                <span>{{ item.frame_type === 'NGO' ? '' : item.party_name }}</span>
            </template>
            <template v-slot:item.premium="{ item }">
                <p>{{ item.premium.toString() === 'true' ? 'Yes' : 'No' }}</p>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2 warning--text" @click="editItem(item)">
                    mdi-pencil
                </v-icon>
                <v-icon small @click="deleteItem(item)" class="error--text">
                    mdi-delete
                </v-icon>
            </template>
            <template v-slot:no-data>
                <v-progress-circular v-if="loading" indeterminate color="secondary"></v-progress-circular>
                <v-icon v-else x-large color="grey lighten-1">
                    mdi-tray-remove
                </v-icon>

            </template>
        </v-data-table>
    </div>
</template>
<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'Frames',
    mixins: [validationMixin],
    data: () => ({
        imgdialog: false,
        selectedSrc: null,
        showCustomFrames: false,
        selectedSocialImage: null,
        selectedFrameTemplate: null,
        selectedCustomFrame: null,
        headers: [
            {
                text: 'Cover',
                value: 'img',
                sortable: false,
                align: 'start'
            },
            {
                text: 'Social Cover',
                value: 'social_img',
                sortable: false,
                align: 'start'
            },
            {
                text: 'Frame Template',
                value: 'sample_template',
                sortable: false,
                align: 'start'
            },
            { text: 'Name', value: 'name' },
            { text: 'Frame Type', value: 'frame_type' },
            { text: 'Font Color', value: 'font_colour' },
            { text: 'Social Font Color', value: 'social_font_colour' },
            { text: 'Party Name', value: 'party_name' },
            { text: 'Premium', value: 'premium', sortable: false },
            { text: 'Actions', value: 'actions', sortable: false },
        ],
        data: [],
        allData: [],
        apiUrl: process.env.VUE_APP_API_URL,
        loading: true,
        dialog: false,
        dialogDelete: false,
        error: '',
        editedIndex: -1,
        selectedImage: null,
        customers: [],
        politicals: [],
        editedItem: {
            id: '',
            name: '',
            img: null,
            social_img: null,
            sample_template: null,
            font_colour: '',
            social_font_colour: '',
            designation_font_colour: '',
            political_party: '',
            frame_type: '',
            premium: false,

            is_custom: false,
            user_available_to: [],
        },
        defaultItem: {
            id: '',
            name: '',
            img: null,
            social_img: null,
            sample_template: null,
            font_colour: '',
            social_font_colour: '',
            designation_font_colour: '',
            political_party: '',
            frame_type: '',
            premium: false,

            is_custom: false,
            user_available_to: [],
        },
        filterItems: [],
        filterByName: '',
        filterByState: '',
        filterByPhone: ''
    }),
    created() {
        this.getFrames();
        this.getCustomers();
        this.getAllPoliticals()
    },
    methods: {
        changeView() {
            this.showCustomFrames = !this.showCustomFrames

            // using all data to filter
            if (this.showCustomFrames) {
                this.data = this.allData.filter((el) => {
                    return el.is_custom === true;
                });
            } else {
                // otherwise show only non-custom frames
                this.data = this.allData.filter((el) => {
                    return el.is_custom === false;
                });
            }

            console.log("Show Custom Frames: ", this.showCustomFrames)
            if (this.showCustomFrames === true) {
                console.log("Showing Custom Frames")
            } else {
                console.log("Showing Non-Custom Frames")
            }
        },
        openImage(src) {
            this.selectedSrc = src
            this.imgdialog = true
        },
        editItem(item) {
            console.log("Item: ", item);
            this.selectedImage = item.img
            this.selectedSocialImage = item.social_img
            this.selectedFrameTemplate = item.sample_template
            this.editedIndex = this.data.indexOf(item);
            this.editedItem = Object.assign({}, item);


            this.dialog = true;
            console.log(this.editedItem);
        },
        deleteItem(item) {
            this.editedIndex = this.data.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.editedItem.premium = item.premium;
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            this.deleteFrame(this.editedItem.id)
            this.closeDelete();
        },
        close() {
            this.selectedImage = null
            this.selectedSocialImage = null
            this.dialog = false;
            this.$v.$reset()
            this.error = ''
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        save() {
            this.editedIndex == -1 ? this.addFrame() : this.updateFrame()
        },
        //Frames API Calls
        getFrames() {
            // empty data
            this.data = []
            this.loading = true
            this.$Axios
                // original - get-frames
                // new added - get-frames-demo
                .get('/api/v1/frame?admin=true')
                .then((res) => {
                    console.log(res.data);
                    this.allData = res.data;
                    if (this.showCustomFrames) {
                        this.data = res.data.filter((el) => {
                            return el.is_custom === true;
                        });
                    } else {
                        // otherwise show only non-custom frames
                        this.data = res.data.filter((el) => {
                            return el.is_custom === false;
                        });
                    }
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false
                })
        },
        getImage(source) {
            let formData = new FormData();
            if (source === 'frame') formData.append('img', this.editedItem.img);
            if (source === 'social_frame') formData.append('img', this.editedItem.social_img);
            if (source === 'sample_frame_template') formData.append('img', this.editedItem.sample_template);
            formData.append('source', source);
            this.$Axios({
                method: 'post',
                url: '/api/v1/util/upload-image',
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' },
            })
                .then(({ data }) => {
                    if (source === 'frame') {
                        this.selectedImage = data.img
                    }
                    else if (source === 'social_frame') {
                        this.selectedSocialImage = data.img
                    }
                    else if (source === 'custom_frame') {
                        this.selectedCustomFrame = data.img
                    }
                    else {
                        this.selectedFrameTemplate = data.img
                    }
                })
                .catch((e) => {
                    console.log(e)
                });
        },
        addFrame() {

            
            if (this.$v.$error && this.editedItem.frame_type == "Political") {
                return
            } else {
                if (this.$v.editedItem.img.$error || this.$v.editedItem.font_colour.$error || this.$v.editedItem.name.$error || this.$v.editedItem.frame_type.$error) {
                    return
                }
            }

            let data = {}
            data.name = this.editedItem.name
            data.premium = this.editedItem.premium
            data.frame_type = this.editedItem.frame_type
            data.is_custom = this.editedItem.is_custom
            data.img = this.selectedImage

            if (data.is_custom) {
                data.user_available_to = this.editedItem.user_available_to
            } else {
                data.social_img = this.selectedSocialImage
                data.sample_template = this.selectedFrameTemplate
                data.font_colour = this.editedItem.font_colour
                data.social_font_colour = this.editedItem.social_font_colour
                data.designation_font_colour = this.editedItem.designation_font_colour
            }
          
            if (this.editedItem.frame_type == 'Political') {
                // if the political_party option selected is "Select All" then send all the political parties
                if (this.editedItem.political_party.includes("Select All")) {
                    data.political_party = this.politicals.map((el) => {
                        return el.value;
                    });
                    data.political_party.shift();
                } else {
                    data.political_party = this.editedItem.political_party;
                }
            }

            this.$Axios
                .post('/api/v1/frame/', data)
                .then(() => {
                    this.getFrames();
                    this.close();
                })
                .catch((e) => {
                    if (e.response.status === 422) {
                        alert('Missing required fields!');
                    } else {
                        console.log(e?.response);
                    }
                });
        },
        updateFrame() {
            this.error = ''
            this.$v.$touch()
            let data = {}
            console.log(this.editedItem.premium !== undefined, this.editedItem.premium !== null, this.editedItem.premium);
            if (this.editedItem.premium !== undefined && this.editedItem.premium !== null) data.premium = this.editedItem.premium.toString()
            if (this.editedItem.frame_type == 'Business') data.frame_type = this.editedItem.frame_type
            if (this.editedItem.frame_type && this.editedItem.political_party) data.frame_type = this.editedItem.frame_type
            if (this.editedItem.frame_type.toLowerCase() === 'ngo') data.frame_type = 'NGO'
            if (this.editedItem.name) data.name = this.editedItem.name

            data.img = this.selectedImage

            if (this.editedItem.is_custom) {
                data.user_available_to = this.editedItem.user_available_to
            } else {
                if (this.editedItem.social_img) data.social_img = this.selectedSocialImage
                if (this.editedItem.sample_template) data.sample_template = this.selectedFrameTemplate
                if (this.editedItem.font_colour) data.font_colour = this.editedItem.font_colour
                if (this.editedItem.social_font_colour) data.social_font_colour = this.editedItem.social_font_colour
                if (this.editedItem.designation_font_colour) data.designation_font_colour = this.editedItem.designation_font_colour
            }

            if (this.editedItem.frame_type == 'Political') {
                // if the political_party option selected is "Select All" then send all the political parties
                if (this.editedItem.political_party.includes("Select All")) {
                    data.political_party = this.politicals.map((el) => {
                        return el.value;
                    });
                    data.political_party.shift();
                } else {
                    data.political_party = this.editedItem.political_party;
                }
            }

            console.log("Edited Item: ", this.editedItem)
            console.log("Sending data: ", data);

            // let formData = new FormData();
            // if (this.editedItem.name) formData.append('name', this.editedItem.name);
            // if (this.editedItem.img) formData.append('img', this.selectedImage);
            // if (this.editedItem.social_img) formData.append('social_img', this.selectedSocialImage);
            // if (this.editedItem.font_colour) formData.append('font_colour', this.editedItem.font_colour);
            // if (this.editedItem.social_font_colour) formData.append('social_font_colour', this.editedItem.social_font_colour);
            // if (this.editedItem.political_party) formData.append('political_party', this.editedItem.political_party)
            // if (this.editedItem.premium) formData.append('political_party', this.editedItem.premium)
            // if (this.editedItem.frame_type) formData.append('frame_type', this.editedItem.frame_type);
            // console.log('adding item : ', formData);



            this.$Axios.put('/api/v1/frame/' + this.editedItem.id, data)
                .then((res) => {
                    console.log("Data: ", res.data);
                    this.getFrames();
                    this.close();
                })
                .catch((e) => {
                    console.log(e)
                    alert(e)
                });
        },
        getCustomers() {
            this.loading = true
            this.$Axios
                .get('/api/v1/customer')
                .then((res) => {
                    console.log(res.data);
                    // this.customers = res.data;

                    this.customers = res.data.map((el) => {
                        return { value: el.user_id, text: el.phone }
                    })
                    // add select all option
                    // this.customers.unshift({ value: "Select All", text: "Select All" });

                    // this.politicals = res.data.map((el) => {
                    //     return { value: el.id, text: el.name }
                    // })
                    // // add select all option
                    // this.politicals.unshift({ value: "Select All", text: "Select All" });
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false
                })
        },
        deleteFrame(id) {
            this.$Axios
                .delete('/api/v1/frame/' + id)
                .then(() => {
                    this.getFrames();
                })
                .catch((e) => {
                    console.log(e);
                })
        },
        getAllPoliticals() {
            this.$Axios
                .get('/api/v1/political/')
                .then((res) => {
                    this.politicals = res.data.map((el) => {
                        return { value: el.id, text: el.name }
                    })
                    // add select all option
                    this.politicals.unshift({ value: "Select All", text: "Select All" });

                })
                .catch((e) => {
                    console.log(e);
                })
        }
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'New Frame' : 'Edit Frame';
        },
        formIcon() {
            return this.editedIndex === -1 ? 'mdi-plus' : 'mdi-edit';
        },
        filtredItems() {
            if (this.filter) return this.desserts.filter(item => item.name.includes(this.filter)
                || item.license.includes(this.filter)
                || item.email.includes(this.filter)
                || item.db.includes(this.filter))
            else return this.desserts
        },
        //// Vuelidate Errors
        nameErrors() {
            const errors = []
            if (!this.$v.editedItem.name.$dirty) return errors
            !this.$v.editedItem.name.required && errors.push('Name is required !')
            return errors
        },
        imgErrors() {
            const errors = []
            if (!this.$v.editedItem.img.$dirty) return errors
            !this.$v.editedItem.img.required && errors.push('Cover is required !')
            return errors
        },
        social_imgErrors() {
            const errors = []
            if (!this.$v.editedItem.social_img.$dirty) return errors
            !this.$v.editedItem.social_img.required && errors.push('Social Frame is required !')
            return errors
        },
        font_colourErrors() {
            const errors = []
            if (!this.$v.editedItem.font_colour.$dirty) return errors
            !this.$v.editedItem.font_colour.required && errors.push('Font color is required')
            return errors
        },
        social_font_colourErrors() {
            const errors = []
            if (!this.$v.editedItem.social_font_colour.$dirty) return errors
            !this.$v.editedItem.social_font_colour.required && errors.push('Social Font color is required')
            return errors
        },
        designation_font_colourErrors() {
            const errors = []
            if (!this.$v.editedItem.designation_font_colour.$dirty) return errors
            !this.$v.editedItem.designation_font_colour.required && errors.push('Designation Font color is required')
            return errors
        },
        political_partyErrors() {
            const errors = []
            if (!this.$v.editedItem.political_party.$dirty) return errors
            !this.$v.editedItem.political_party.required && errors.push('Political party is required')
            return errors
        },
        typeErrors() {
            const errors = []
            if (!this.$v.editedItem.frame_type.$dirty) return errors
            !this.$v.editedItem.frame_type.required && errors.push('Type is required')
            return errors
        },
        filterFinalData() {
            if (this.filterItems.length === 0) {
                return this.data
            } else {
                //   //moment(this.editedItem.date).format('YYYY-MM_DD')
                let data = this.data
                if (this.filterItems.includes('Phone') && this.filterByPhone) {
                    data = data.filter((el) => {
                        // convert the user_available_to from the user id to the phone number and check if it includes the filterByPhone
                        return el.user_available_to.map((el) => {
                            return this.customers.find((customer) => {
                                return customer.value === el
                            }).text
                        }).includes(this.filterByPhone)
                    });
                }
                return data
            }
        },
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
    },
    validations: {
        editedItem: {
            name: {
                required
            },
            img: {
                required,
            },
            social_img: {
                required,
            },
            font_colour: {
                required
            },
            social_font_colour: {
                required
            },
            designation_font_colour: {
                required
            },
            political_party: {
                required
            },
            premium: {
                required
            },
            frame_type: {
                required
            }
        },
    },
};
</script>